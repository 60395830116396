import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "../images/mancity-horizontal-white.png";
import HeaderRightLogo from "../images/Brighton_&_Hove_Albion_logo.svg.png";
// import HeaderRightLogo from '../images/nottingham-forest.png'
import TopBg from "../images/top-bg.png";
import LeftBg from "../images/left-bg.jpg";
import RightBg from "../images/right-bg.jpg";
import SignUp from "../images/playicon.png";
import Trophy from "../images/winicon.png";
import Charity from "../images/fundicon.png";
import Bg from "../images/bg.svg";
import Arrow from "../images/arrow.svg";
import LogoFoundationGray from "../images/mancity-horizontal-dark.png";
import CurevedDot from "../images/cureved-dot.svg";
import FooterIconAddress from "../images/footer-icon-address.svg";
import FooterIconEmail from "../images/footer-icon-email.svg";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter-x-new.svg";
import Instagram from "../images/instagram.svg";
import Visa from "../images/visa.svg";
import Mastercard from "../images/mastercard.svg";
import LogoFoundationWhite from "../images/logo-foundation-white.svg";
import HeaderLeftLogo from "../images/man-city.png";
import LogoRed from "../images/mancity-horizontal-dark.png";
import Cup from "../images/cup.svg";
import "../images/HeroBg.png";
import "../App.css";
import TestimonialSlider from "../components/testimonialSlider";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import useDocumentTitle from "../components/useDocumentTitle";
import Moment from "moment";
import PrizeSlider from "../components/prizeSlider";
import loaderSpinner from "../images/Loader.gif";

import { toast, ToastContainer } from "react-toastify";
import TicketImg1 from "../images/ticket-img1.svg";
import TicketImg2 from "../images/ticket-img2.svg";
import TicketImg3 from "../images/ticket-img3.svg";
import NextArrow from "../images/next-arrow.svg";
import Cross from "../images/cross.svg";
import FreeEntryIcon from "../images/free-entry-icon.svg";
import StripeCheckout from "react-stripe-checkout";
import { Globalvar } from "../components/global";
import StripeForm from "../components/stripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-bootstrap/Modal";
import { Elements, useElements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
import { addToCart, initiateCheckout } from "../utils/meta/metaPixelEvent";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Home() {
  // useDocumentTitle('Home');
  const [scroll, setScroll] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  const [login, setLogin] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").loggedin : false);
  const [DrawData, setDrawData] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [SubscriptionPlan, setSubscriptionPlan] = useState(true);
  const [Freemodal, setFreemodal] = useState(false);
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [amount, setAmount] = useState(5);
  const [entries, setEntries] = useState(1);
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);
  const [userEmail, setEmail] = useState();
  const [fullName, setFullName] = useState("");
  const [userFirstName, setFirstName] = useState("");
  const [userLastName, setLastName] = useState("");
  const [oneShow, setOneShow] = useState(false);
  const [isoneoff, setIsoneoff] = useState(false);
  const [isStripe, setStripe] = useState(false);
  const [PrizeData, setPrizeData] = useState([]);
  const [UserID, setUserId] = useState(secureLocalStorage.getItem("LogObject") ? secureLocalStorage.getItem("LogObject").userID : "");
  const userData = secureLocalStorage.getItem("LogObject");

  const parser = new DOMParser();
  const [totalQuantities, setTotalQuantities] = useState(0);
  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showPlayButton, setShowPlayButton] = useState(true);

  const handlePlay = () => {
    setShowPlayButton(false);
    document.getElementById("video").play();
  };

  const handlePause = () => {
    setShowPlayButton(true);
  };

  function logout() {
    secureLocalStorage.clear();
    axios.delete(APIURL + "/auth/logout");
    setLogin(false);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_content: urlParams.get("utm_content")
    };
    secureLocalStorage.setItem("utmParams", JSON.stringify(utmParams));
    console.log("utmParams", utmParams);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    const urlParams = new URLSearchParams(window.location.search);

    axios.get(APIURL + "/sites/" + siteId + "/draws").then(res => {
      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter(item => item.drawIsClosed != 1);
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      // Check if Entry is for the event Prize Draw
      if (urlParams.has("eventPrizeDraw")) {
        const eveningPrizeDrawId = "705bd487-133c-42bd-8e58-34702073c6d6";
        const eveningPrizeDraw = alldraw.find(item => item.drawID === eveningPrizeDrawId);
        if (eveningPrizeDraw) {
          selectedDraw = eveningPrizeDraw;
        }
      }
      setDrawData(selectedDraw);
    });
  }, []);

  const navigate = useNavigate();
  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  function hideModal() {
    setOneShow(false);
  }
  Globalvar.amount = 5;
  Globalvar.entries = 1;

  function calculateTotalQuantity(PrizeData) {
    let sum = 0;
    {
      PrizeData.forEach(item => {
        sum += item.prizeQuantity;
      });
    }
    return sum;
  }

  useEffect(() => {
    let selectedDrawID_ = "";
    axios.get(APIURL + "/sites/" + siteId + "/draws").then(res => {
      let alldraw = res.data.content;
      let activeDraw = res.data.content.filter(item => item.drawIsClosed != 1);
      let selectedDraw = {};
      if (activeDraw.length > 0) {
        selectedDraw = activeDraw[0];
      } else {
        selectedDraw = alldraw[0];
      }
      selectedDrawID_ = selectedDraw.drawID;
      setDrawData(selectedDraw);
    });

    if (selectedDrawID_.length > 1) {
      axios
        .get(APIURL + `/draws/${selectedDrawID_}/prizes`, {
          withCredentials: true,
          credentials: "same-origin"
        })
        .then(res => {
          setPrizeData(res?.data?.content);
          setTotalQuantities(calculateTotalQuantity(res?.data?.content));
        });
    }
  }, []);

  const handleClick = (param, value, tickets) => {
    switch (param) {
      case "first":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(true);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "second":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(true);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "third":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fourth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(true);
        setIsActive5(false);
        setIsActive6(false);
        return;
      case "fifth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(true);
        setIsActive6(false);
        return;
      case "sixth":
        setAmount(value);
        Globalvar.amount = value;
        Globalvar.entries = tickets;
        secureLocalStorage.setItem("amount", value);
        secureLocalStorage.setItem("entries", tickets);
        setEntries(tickets);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(false);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(true);
        return;
      default:
        setEntries(5);
        setAmount(25);
        setIsActive1(false);
        setIsActive2(false);
        setIsActive3(true);
        setIsActive4(false);
        setIsActive5(false);
        setIsActive6(false);
        return;
    }
  };

  function toPaymentDetails() {
    setSubscriptionPlan(false);
    setPaymentDetails(true);
  }
  function toPaymentDetails() {
    setSubscriptionPlan(false);
    setPaymentDetails(true);

    // Data Layer Push for Add to Cart event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries
          }
        ]
      }
    });

    // Data Layer Push for Begin Checkout event
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        user_id: userData?.userID ? userData?.userID : "Guest User",
        user_email: userData?.email ? userData?.email : "Guest User",
        items: [
          {
            item_name: DrawData.drawName,
            item_id: DrawData.drawID,
            price: amount,
            item_brand: "Sport",
            item_category:
              DrawData.drawCategoryID === 1
                ? "Fixed Cash Prize"
                : DrawData.drawCategoryID === 2
                ? "Split Cash Prize"
                : DrawData.drawCategoryID === 3
                ? "Experiential"
                : DrawData.drawCategoryID === 4
                ? "Hybrid"
                : "",
            item_variant: !isoneoff ? "Subscription" : "One-off",
            quantity: entries
          }
        ]
      }
    });

    const currency = "GBP";
    const value = amount;
    addToCart(currency, value);
    initiateCheckout(currency, value);
  }

  async function checkConsents() {
    const fn = fullName.split(" ");

    // const checkConsents = () => {
    if (!consent3) {
      toast("Please read and accept Terms & Conditions", { type: "error" });
    } else if (fullName === "") {
      toast.error(`Please Enter Your valid Full Name`);
    } else if (!fn[0] || fn[0] === "") {
      toast.error(`Please Enter Your First and last Name`);
    } else if (!fn[1] || fn[1] === "") {
      toast.error(`Please Enter Your last Name`);
    } else if (userEmail == "") {
      toast("Please Enter the Email Address", { type: "error" });
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userEmail)) {
      toast.error(`Invalid Email`);
    } else {
      setStripe(true);
      setOneShow(true);

      //ValidateEmail
      // const POSTCODER_KEY = process.env.POSTCODER_API_KEY || 'PCWW5-FVLAY-K6FGB-KSTYM'
      // await axios.get(`https://ws.postcoder.com/pcw/${POSTCODER_KEY}/email/${userEmail}`).then((res) => {
      //   console.log("res.data", res.data)
      //   if (res.data.valid) {
      //     setStripe(true);
      //     setOneShow(true);
      //   } else {
      //     toast.error(`Invalid Email`);
      //   }
      // });
      setStripe(true);
      setOneShow(true);
    }
    // }
  }

  let drawContent;
  let nextFixtureName = "";
  let leagueName = "";
  let fixtureData = "";

  try {
    drawContent = JSON.parse(DrawData.drawContent);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  if (drawContent) {
    // Access properties from the parsed JSON
    nextFixtureName = drawContent.nextfxitureName;
    leagueName = drawContent.leagueName;
    fixtureData = drawContent.fixtureData;

    // Use nextFixtureName in your JSX or template
  } else {
    // Handle the case where parsing failed
    // This could be due to invalid JSON format
  }

  return (
    <>
      <Helmet>
        <title>Win Big in the Man City Community Draw | Support CITC and Manchester Community</title>
        <meta
          name="description"
          content="Kick off your winning streak! Join the Man City Community Prize Draw to clinch incredible prizes, and play a vital role in supporting City in the Community's enduring mission to uplift communities."
        />
      </Helmet>
      <header
        className={
          scroll
            ? "home transation-ease-in-out site-header font-face-sh py-2 position-sticky top-0 bg-branding-1 z-index-9"
            : "transation-ease-in-out site-header font-face-sh py-2"
        }
      >
        <div className="container">
          <div className="inner-header row d-flex align-items-center">
            <div className="logo col-sm-6 col-md-6 col-lg-6 col-6">
              <Link to={"/"}>
                <img className="start-0 not-responsive" src={scroll ? LogoWhite : LogoRed} alt="logo-white" style={{ width: 270 }} />
              </Link>
              <Link to={"/"}>
                <img className="start-0 only-responsive d-none" src={LogoWhite} alt="logo-white" style={{ width: 270 }} />
              </Link>
            </div>
            <div className="desktop-menu col-md-6 d-flex justify-content-end align-items-center">
              {login ? (
                <>
                  <DropdownButton align="end" title="Account" id="dropdown-menu-align-end" className="text-white transparent">
                    <Dropdown.Item eventKey="1" href="/account">
                      My Impact
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" href="/account/prizes">
                      Prizes
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" href="/account/subscription">
                      Manage Subscription
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="6" href="/account/payment-method">
                      Manage Payment
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4" href="/account/details">
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="5" onClick={logout}>
                      Log out
                    </Dropdown.Item>
                  </DropdownButton>
                  <Link
                    to={"/subscription-plan"}
                    className="py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh"
                  >
                    Enter The Draw
                  </Link>
                </>
              ) : (
                <>
                  <div className="login-signup py-3 px-4 bg-white rounded-pill branding-2 border border-2 border-white ">
                    {/* <img src={require('../images/user.png')} alt="User"/> */}
                    <Link to={"/login"} className="branding-1 text-decoration-none cta-large-font" style={{ marginRight: 20 }}>
                      Login
                    </Link>
                    /
                    <Link to={"/signup"} className="branding-1 text-decoration-none cta-large-font">
                      Sign up
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none">
              <div className={Sidebar ? "MenuButton_rootActive" : "MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
                <div className={Sidebar ? "MenuButton_firstLineActive" : "MenuButton_firstLine"}></div>
                <div className={Sidebar ? "MenuButton_secondLineActive" : "MenuButton_secondLine"}></div>
                <div className={Sidebar ? "MenuButton_thirdLineActive" : "MenuButton_thirdLine"}></div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mySidebar"
          className={
            Sidebar
              ? "open sidebar bg-light h-100 top-0 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden py-4 px-4"
              : "sidebar h-100 w-0 end-0 top-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden"
          }
        >
          <div className="logo-red col-md-12">
            <Link to={"/"}>
              <img className="start-0" src={LogoRed} alt="logo-white" style={{ width: 270 }} />
            </Link>
          </div>
          <div className="mobile-menu-nav col-md-12 mt-5 d-flex flex-column">
            {login ? (
              <>
                <Link
                  to={"/subscription-plan"}
                  className="text-center mb-3 py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh"
                >
                  Enter The Draw
                </Link>
                <div className="fs-6 mb-1" style={{ color: "#999" }}>
                  <strong>Account</strong>
                </div>
                {/* <Link to={"/login"} className='black-clr text-decoration-none fs-5 font-face-sh-bold branding-1-hover'>Login</Link>
          <Link to={"/signup"} className='py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1'>Join Now</Link> */}
                <Link to={"/account"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  My Impact
                </Link>
                <Link to={"/account/prizes"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Prizes
                </Link>
                <Link to={"/account/subscription"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Subscription
                </Link>
                <Link to={"/account/payment-method"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Manage Payment
                </Link>
                <Link to={"/account/details"} className="black-clr text-decoration-none fs-5 branding-1-hover py-1">
                  Settings
                </Link>
                <hr />
                <Link onClick={logout} to={"/"} className="black-clr text-decoration-none fs-5 branding-1-hover">
                  Log Out
                </Link>
              </>
            ) : (
              <>
                <Link to={"/login"} className="black-clr text-decoration-none fs-5 font-face-sh branding-1-hover">
                  Login
                </Link>
                <Link
                  to={"/signup"}
                  className="py-3 px-5 border border-2 border-branding-1 rounded-pill black-clr text-decoration-none branding-1-hover fs-5 mt-3 w-75 text-center lh-1"
                >
                  Join Now
                </Link>
              </>
            )}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-3 mt-auto ">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2 font-face-sh-bold">Follow us</p>
                <div className="d-flex">
                  <a className="branding-2 text-decoration-none" href="https://facebook.com/PlayFundWin/" target="_blank">
                    <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                  </a>
                  <a className="branding-2 text-decoration-none" href="https://twitter.com/PlayFundWin" target="_blank">
                    <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                  </a>
                  <a className="branding-2 text-decoration-none" href="https://www.instagram.com/playfundwin" target="_blank">
                    <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 branding-1">
                <p className="mt-4 pt-2" style={{ fontSize: 14 }}>
                  City in the Community Prize Draw is operated by{" "}
                  <a className="text-decoration-none branding-1 branding-1-hover" href="https://playfundwin.com/" target="_blank">
                    Play Fund Win
                  </a>
                </p>
                <div className="d-flex font-face-sh-bold" style={{ fontSize: 14 }}>
                  <Link className="branding-1 text-decoration-none pe-5 branding-1-hover" onClick={() => window.scrollTo(0, 0)} to={"/terms-conditions"}>
                    Terms & Conditions
                  </Link>
                  <Link className="branding-1 text-decoration-none branding-1-hover" onClick={() => window.scrollTo(0, 0)} to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="hero-section herosec-img font-face-sh py-sm-" style={{ marginTop: "-165px", padding: "170px 0 20px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center" style={{ paddingTop: "190px" }}>
              <h1 className="title-managed position-relative lh-4 l-title w-auto mt-4 mb-0 display-4 font-face-sh-bold text-uppercase" style={{ zIndex: 9 }}>
                It’s a win win!
              </h1>
              <h3 className="w-100 fs-5 body-bold bg-white lh-2 p-3 mr-0">
                From just £5 per ticket, you'll be helping to empower people to live healthier lives through football, by supporting City in the Community.{" "}
              </h3>
              {/* <Link to={'/signup'} className="btn text-uppercase bg-branding-green border-0 w-50 rounded-pill py-3 mt-5 lh-1 desktop-btn text-light ">Join Now</Link> */}
            </div>
            {/* <div className='col-sm-12 col-md-5 col-lg-5 hero-right-block d-flex justify-content-end'>
            <div className='match-card w-100 ms-2'>
              <div className='matchcard-header px-4 py-3 d-flex align-items-center justify-content-between bg-branding-1'>
                <p className='text-white fs-5 m-0'>In support of</p>
                <img className='start-0' style={{width: 178}} src={LogoFoundationWhite} alt="LogoFoundationWhite" />
              </div>
              <div className='matchcard-logosblock bg-white text-center p-5' style={{borderBottomLeftRadius: 90}}>
                <p className='branding-2 fs-4'>Winners next announced:</p>
                <div className='d-flex align-items-center justify-content-between py-5 pb-5'>
                  <img className='start-0' style={{width: 270}} src={Favicon} alt="Favicon" />
                  <h2 className="branding-2 lh-1 display-3 font-face-sh-bold">VS</h2>
                  <img className='start-0' style={{width: 110}} src={vsLogo} alt="vsLogo" />
                </div>
                <p className='branding-2 fs-4 pt-2 m-0'>{Moment(DrawData?.drawEndDate).format('LLL')}</p>
              </div>
            </div>
          </div> */}
            {/* <div className='prizes-section-data text-center d-flex border-white border-bottom rounded-top' style={{justifyContent : 'center'}}>
            <div className='prizes-section-subdata'>
              <h3 className="w-auto fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{borderTopLeftRadius : 20,borderTopRightRadius : 20}}>WIN PRIZES EVERY MONTH</h3>
            </div>
          </div> */}

            {/* <div className='mobile-bottom d-none'>
              <Link to={"/signup"} className="btn bg-branding-green text-uppercase border-0 w-50 rounded-pill py-3 mt-5 lh-1 text-white">Join Now</Link>
            </div> */}

            <div className="prizes-section-data mb-3 text-center d-flex border-white border-bottom rounded-top" style={{ justifyContent: "center" }}>
              <div className="prizes-section-subdata">
                <h3 className="w-auto fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                  Win big, including cash prizes, signed Manchester City shirts and more!
                </h3>
              </div>
            </div>
            <PrizeSlider />
            {/* <h3 className="w-100 fs-5 text-center text-white lh-2 p-3 fw-normal mb-2 mr-0">Stay tuned for half-time when winners are informed via email!</h3>
            <div className='join-now-button text-center'><Link to={'/signup'} className="btn text-uppercase bg-white border-0 w-auto rounded-pill py-3 mt-1 lh-1 desktop-btn branding-1 ">Join Now</Link></div> */}
          </div>
        </div>
      </section>

      {/* <div className='prizes-sliders py-5 pt-0 bg-branding-1'>
      <div className='container'> */}
      {/* <img className="" src={require('../images/mascot.jpg')} width="100%" height="600px" style={{objectFit : 'cover', objectPosition : '10%'}}/> */}
      {/* <div className='prizes-section-data mb-3 text-center d-flex border-white border-bottom rounded-top' style={{justifyContent : 'center'}}>
            <div className='prizes-section-subdata'>
              <h3 className="w-auto fs-5 lh-2 p-3 fw-normal mb-0 mr-0 mt-5 branding-1 bg-white" style={{borderTopLeftRadius : 20,borderTopRightRadius : 20}}>WIN AMAZING PRIZES</h3>
            </div>
          </div>
            <PrizeSlider/>
            <h3 className="w-100 fs-5 text-center text-white lh-2 p-3 fw-normal mb-2 mr-0">Stay tuned for half-time when winners are informed via email!</h3>
            <div className='join-now-button text-center'><Link to={'/signup'} className="btn text-uppercase bg-white border-0 w-auto rounded-pill py-3 mt-1 lh-1 desktop-btn branding-1 ">Join Now</Link></div>
            
          </div>
          </div>*/}

      <section className="ticket-data bg-branding-btn-sec">
        <div className="container">
          <div className="ticket-section pb-5">
            <div className="py-1 d-flex align-items-center justify-content-center mb-3">
              <img src={require("../images/playicon.png")} width="33px" />
              <h2 className="mt-2 mx-3">
                <span className="position-relative m-title branding-2 font-face-sh-bold">ENTER THE DRAW</span>
              </h2>
            </div>
            {/* <p className='fs-5 text-center text-white'>How many tickets would you like to buy? £5 per ticket</p> */}

            {!paymentDetails && SubscriptionPlan ? (
              <section className="subscription-plan font-face-sh">
                <div className="subscription-plan-container mx-auto">
                  <div
                    className={Freemodal ? "steps-subscription-plan position-relative pt-0 d-none" : "steps-subscription-plan position-relative pt-0 d-block"}
                    style={{ zIndex: 999 }}
                  >
                    <div className="step-subscription-plan-selector pt-4">
                      <ul className="d-flex unstyled list-unstyled gap-3">
                        <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 5, 1)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #001838", paddingBottom: 15 }}>
                              1 Ticket
                            </h4>
                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">5</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">per month</span>}
                            </div>
                          </div>
                        </li>
                        <li className={isActive2 ? "active" : ""} onClick={() => handleClick("second", 10, 2)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #001838", paddingBottom: 15 }}>
                              2 Tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">10</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">per month</span>}
                            </div>
                          </div>
                        </li>
                        <li className={isActive3 ? "position-relative active" : "position-relative"} onClick={() => handleClick("third", 25, 5)}>
                          <div
                            className="subscription-plan-emphasis branding-1 d-inline-block lh-normal position-absolute start-0 end-0 m-auto bg-branding-2"
                            style={{
                              fontSize: 14,
                              padding: "7px 6px 7px 7px",
                              width: 150,
                              top: -40,
                              zIndex: 999,
                              textAlign: "center",
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20
                            }}
                          >
                            Popular
                          </div>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #001838", paddingBottom: 15 }}>
                              5 Tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">25</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">per month</span>}
                            </div>
                          </div>
                        </li>
                        <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 50, 10)}>
                          <div className="ticket-title-sec px-3 py-5 position-relative" style={{ borderRadius: 6, filter: "drop-shadow(0 4px 28px rgba(0,0,0,.12))" }}>
                            <h4 className="fs-5 text-center mb-4 mb-0" style={{ color: "#302d51", borderBottom: "2px solid #001838", paddingBottom: 15 }}>
                              10 Tickets
                            </h4>

                            <div className="price text-center posiion-relative">
                              <span className="display-6">£</span>
                              <span className="display-4">50</span>
                              <br />
                              {isoneoff ? " " : <span className="branding-2 fs-6">per month</span>}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="subscription-plan-selector-amountarea bg-white p-3 mt-4" style={{ borderRadius: 6 }}>
                      <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="subscription-plan-price d-flex justify-content-center">
                            <div className="subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1">£</div>
                            <div className="subscription-plan-price_amount branding-1 display-3 font-face-sh-bold">{amount}</div>
                            <div className="subscription-plan-price_term text-black lh-1 mt-3 ms-1" style={{ fontSize: 12 }}>
                              {isoneoff ? (
                                ""
                              ) : (
                                <>
                                  per
                                  <br />
                                  month
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="subscription-plan-selector-entries d-flex align-items-center">
                            <div className="draw-entries-icon position-relative" style={{ backgroundColor: "#f3f2fb", width: 52, height: 52, borderRadius: "50%" }}></div>
                            <ul className="unstyled list-unstyled ul-tick ms-3 mb-0" style={{ fontSize: 12 }}>
                              <li>Auto-entry into every draw</li>
                              <li>Regular charitable support</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="step-subscription-button text-center mb-4 d-flex justify-content-center">
                      {console.log("Moment()", Moment())}
                      {Moment() < Moment(DrawData.drawStartDate) ? (
                        <>
                          <button
                            onClick={handleShow}
                            className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                            style={{ width: "200px", height: 48 }}
                          >
                            <span>{isoneoff ? "Enter Draw" : "Subscribe Now"}</span>
                          </button>
                          <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal-startShow">
                            <Modal.Header closeButton>
                              <Modal.Title>Draw has not started yet</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="text-center">
                                <img className="mx-auto w-50 mb-3" src={require("../images/mancity-horizontal-dark.png")} style={{ width: 66 }} />
                                <p className="fs-3">
                                  This draw will start at{" "}
                                  <span className="branding-2">{DrawData?.drawEndDate ? Moment(DrawData.drawStartDate).format("LLL") : "-----, /--/--/-- 00:00PM"}</span>
                                </p>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <span variant="secondary" onClick={handleClose}>
                                Close
                              </span>
                            </Modal.Footer>
                          </Modal>
                        </>
                      ) : (
                        <button
                          onClick={() => toPaymentDetails(true)}
                          className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                          style={{ width: "200px", height: 48 }}
                        >
                          <span>{isoneoff ? "Enter Draw" : "Subscribe Now"}</span>
                          {/* <img className='position-absolute top-0 bottom-0 my-auto' src={NextArrow} style={{ marginLeft: 20, height: 40, right: 4 }} /> */}
                        </button>
                      )}
                    </div>
                    <div className="form-check form-switch d-flex justify-content-center pt-0">
                      <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefault" />
                      <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">
                        <p>{!isoneoff ? "Tick to make a one-off entry into the next draw" : "Untick to make a Subscription entry into the next draw"}</p>
                      </label>
                    </div>
                    <div className="terms-cond mt-2 text-center mb-3">
                      <p className="m-0 text-white">
                        Alternative entry routes available,{" "}
                        <Link className="text-decoration-none branding-1 branding-6" to={"/terms-conditions"}>
                          click here
                        </Link>{" "}
                        for terms.
                      </p>
                    </div>
                  </div>
                  <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                    <div className="free-entry-modal_title_block mb-5 pb-5">
                      <img
                        className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                        src={Cross}
                        style={{ width: 56, boxShadow: "0 4px 16px rgb(20 19 38 / 30%)", cursor: "pointer" }}
                        onClick={() => setFreemodal(!Freemodal)}
                      />
                      <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                      <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                        While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                      </p>
                    </div>
                    <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                      <div className="free-entry-icon">
                        <img src={FreeEntryIcon} style={{ width: 24 }} />
                      </div>
                      <div className="free-entry-description ps-4 fs-14 lh-1">
                        <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                        <p>
                          * First Name
                          <br />
                          * Last Name
                          <br />* Email
                        </p>
                        <p>
                          to: PFW Holdings Ltd
                          <br />
                          Play Fund Win Office #300,
                          <br />4 Blenheim Court,
                          <br />
                          Peppercorn Close,
                          <br />
                          Peterborough,
                          <br />
                          PE1 2DU
                          <br />
                          <br />
                          You will be entered into the prize draws for the month in which we receive your post entries.
                          <br />
                          <br />
                          Note: This will not result in any contributions to the Foundation
                        </p>
                        <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                          <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : paymentDetails ? (
              <section className="personal-details font-face-sh py-5">
                <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                      <div
                        className="step-plan_step branding-1 rounded-pill text-center mx-auto"
                        style={{ backgroundColor: "#f7f7fd", width: 112, height: 45, lineHeight: "45px" }}
                      >
                        <strong>Step 2 of 2</strong>
                      </div>
                      {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                      <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                        {isoneoff ? "" : "We’ll take your amount "}
                      </p>
                      <form method="post">
                        {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                        {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                        {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                        <div className="form-checkbox">
                          <ul className="checkbox-list list-unstyled">
                            <li className="checkbox-item mb-4">
                              <label className="input-checkbox d-flex">
                                <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, I’d like to receive updates about City in the Community via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label className="input-checkbox d-flex">
                                <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span className="input-checkbox-label">Yes, City in the Community Foundation can contact me about their work via email.</span>
                              </label>
                            </li>
                            <li className="checkbox-item">
                              <label
                                className="step-payment-details_consent_description mt-5 input-checkbox d-flex"
                                style={{ backgroundColor: "rgb(198,29,35,.1)", color: "#302d51", padding: "14px 18px", borderRadius: 20, fontSize: 13 }}
                              >
                                <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                                <span className="input-checkbox_check"></span>
                                <span>
                                  By clicking on Confirm you agree to City in the Community Draw{" "}
                                  <Link to={"/terms-conditions"} onClick={() => window.scrollTo(0, 0)} style={{ color: "#302d51" }}>
                                    Terms & Conditions of Use
                                  </Link>
                                  ,{" "}
                                  <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                    Privacy Policy
                                  </Link>{" "}
                                  and that you <strong>are at least 18 years old.</strong>
                                </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </form>
                      <button
                        onClick={checkConsents}
                        className="btn bg-branding-green border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                        style={{ width: "100%", height: 48 }}
                      >
                        Pay now
                      </button>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                      <div className="user-details-info bg-branding-1 rounded-4" style={{ padding: "35px 35px 19px", boxShadow: "0 15px 50px -30px #000000" }}>
                        <h3 className="text-light fs-5 mb-4">Summary</h3>
                        <div className="summary-info-impact pb-3">
                          <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 18 }}>
                            {entries} chances to win
                          </div>
                          <div className="summary-info-impact_period lh-1" style={{ color: "rgb(255,255,255,0.7)", paddingLeft: 35 }}>
                            every draw
                          </div>
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        <div className="summary-info-price d-flex align-items-center pb-2">
                          <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                          <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                            {isoneoff ? "" : "per month"}
                          </span>
                        </div>
                        <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                        <p className="summary-info-upgrade-text m-0" style={{ color: "rgb(255,255,255,0.7)" }}>
                          Upgrade or cancel anytime
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>

      {/* <section className='fixtures bg-branding-2 py-5'>
        <div className='container'>
          <div className='team-fixtures bg-white rounded-4 py-5 px-4'>
          <h4 className='pb-1 text-center s-body-sky-blue'>Next Fixture</h4>
            <div className='row align-items-center'>
              <div className='col-md-2 text-center'>
                <img className='start-0' style={{ width: "100%" }} src={HeaderLeftLogo} alt="Favicon" />
              </div>
              <div className='col-md-8 text-center'>
                
                <h4 className='text-center s-title text-uppercase branding-1 pb-4'>{leagueName}</h4>
                <div className='row'>
                  <div className='col-md-5 text-right'><h2 className="s-title text-uppercase lh-1 font-face-sh-bold text-right">Manchester City FC</h2></div>
                  <div className='col-md-2 text-center'><h2 className="branding-1 lh-1 font-face-sh-bold">VS</h2></div>
                  <div className='col-md-5 text-left'><h2 className="s-title text-uppercase lh-1 font-face-sh-bold text-left">{nextFixtureName}</h2></div>
                </div>
                
              </div>
              <div className="col-md-2 text-center">
                <img
                  className="start-0"
                  style={{ width: "100%" }}
                  src={DrawData.drawContent ? (JSON.parse(DrawData.drawContent).vsLogo ? JSON.parse(DrawData.drawContent).vsLogo : HeaderRightLogo) : loaderSpinner}
                  alt="vs Logo"
                />
              </div>
              <p className='s-body-sky-blue text-center pt-4 m-0'>{fixtureData ? Moment(fixtureData).format('LLL') : "-----, /--/--/-- 00:00PM"}</p>
            </div>
          </div>
        </div>
      </section> */}

      <section
        className="mission-section font-face-sh"
        style={{
          paddingTop: 100,
          backgroundImage: `linear-gradient(#6CADDF, rgba(0, 24, 56, 0.04)),url(${require("../images/cityinthecommunity.jpg")})`,
          zIndex: 99,
          position: "relative",
          backgroundSize: "cover",
          backgroundPosition: "top"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <h2 className="l-title-light text-white">About City in the Community</h2>
              <h3 className="text-white l-body pb-5">Empowering healthier lives with city youth through football.</h3>
              <div className="description-data py-5" style={{ position: "relative" }}>
                <p className="default-style text-dark">Founded in 1986, City in the Community is Manchester City's charity.</p>
                <p className="default-style text-dark">
                  We support people across Greater Manchester by empowering healthier lives through football. Our youth-led programmes place physical and mental wellbeing at their
                  core, whilst also creating healthy futures and healthy communities.
                </p>
                <p className="default-style text-dark">
                  During the 2022/23 season, City in the Community delivered 17 programmes to over 18,000 participants across Greater Manchester. Our strategy is to achieve
                  tangible, meaningful impact through regular, sustained and high-quality contact time between its staff and the participants they engage with.{" "}
                </p>
                <p className="default-style text-dark">With support from our fans, we can sustain and develop this important work. </p>

                <Link
                  to="https://www.mancity.com/city-in-the-community"
                  target="_blank"
                  className="cta-large-button-dark btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                  style={{ color: "#fff", width: "200px", height: 48 }}
                >
                  Find out more
                </Link>
              </div>
            </div>
            <div className="col-lg-7">
              <img
                className="mx-auto rounded-3"
                src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/ManCityAbout.jpg"
                style={{ width: "100%", height: "90%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="banners-bees">
        <div className="row ">
          <div className="col-lg-6 p-0 m-0">
            <img
              className="mx-auto"
              src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/ManCity-LeftBanner.jpg"
              style={{ width: "100%", height: "100%", objectFit: "Cover", objectPosition: "top" }}
            />
          </div>
          <div className="col-lg-6 p-0 m-0">
            <img
              className="mx-auto"
              src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/ManCityRight.jpg"
              style={{ width: "100%", height: "100%", objectFit: "Cover", objectPosition: "top" }}
            />
          </div>
        </div>
      </section> */}

      {/* <section className='how-it-works_section font-face-sh bg-position py-5' style={{ backgroundImage: `url(${Bg})`, borderRadius: 10 }}>
        <div className='container-2 bg-white p-5 shadow-lg'>
          <div className='how-it-works_title text-center'>
            <h2 className='black-clr display-5 text-uppercase text-center border-bottom mb-5 mt-2 pb-2 d-inline-block font-face-sh-bold'>How It Works</h2>
          </div>
          <div className='row'>
            <div className='col-md-4 text-center'>
              <img src={SignUp} alt="signup" />
              <h2 className='mt-2'><span className='play-icon position-relative'>Play</span></h2>
              <p className='branding-2 fs-5 text-center lh-1 mt-1'>Set up a monthly donation online and be entered into every draw.</p>
            </div>
            <div className='col-md-4 text-center'>
              <img src={Charity} alt="Charity" />
              <h2 className='mt-2'><span className='fund-icon position-relative'>Fund</span></h2>
              <p className='branding-2 fs-5 text-center lh-1 mt-1'>Become a supporter of City in the Community’s community work.</p>
            </div>
            <div className='col-md-4 text-center'>
              <img src={Trophy} alt="Trophy" />
              <h2 className='mt-2'><span className='win-icon position-relative'>Win</span></h2>
              <p className='branding-2 fs-5 text-center lh-1 mt-1'>Stay tuned for half-time when winners are informed via email!</p>
            </div>
           
          </div>
        </div>



      </section> */}

      <section className="how-it-works_section font-face-sh bg-position py-5 bg-branding-2" style={{ marginTop: -10 }}>
        <div className="container p-5">
          <div className="how-it-works_title text-center">
            <h2 className="text-center mb-5 mt-2 pb-2 d-inline-block m-title text-uppercase branding-1">How It Works</h2>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={SignUp} alt="signup" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative m-title text-uppercase branding-1">Play</span>
                </h2>
              </div>

              <p className="s-body text-center">Enter for your chance to win amazing prizes in the City in the Community Prize Draw.</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={Charity} alt="Charity" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative m-title text-uppercase branding-1">Fund</span>
                </h2>
              </div>

              <p className="s-body text-center">Help to support the fantastic work that City in the Community does.</p>
            </div>
            <div className="col-md-4 text-center">
              <div className="py-3 d-flex align-items-center justify-content-center mb-4">
                <img src={Trophy} alt="Trophy" />
                <h2 className="mt-2 mx-2">
                  <span className="position-relative m-title text-uppercase branding-1">Win</span>
                </h2>
              </div>
              <p className="s-body text-center">The winners will be announced on the 21st of each month.</p>
            </div>
          </div>
          <div className="how-it-works-button text-center pt-4 mt-3">
            {login ? (
              <Link to={"/subscription-plan"} className="bg-branding-1 border-0 rounded-pill py-3  px-4 mt-1 lh-1 cta-large-button-dark" style={{ color: "#fff", width: "200px" }}>
                Find out more
              </Link>
            ) : (
              <Link to={"/signup"} className="bg-branding-1 border-0 rounded-pill py-3  px-4 mt-1 lh-1 cta-large-button-dark" style={{ color: "#fff", width: "200px" }}>
                Find out more
              </Link>
            )}
          </div>
        </div>
      </section>

      <section className="banners-city">
        <div className="container p-5">
          <div className="col-lg-12 p-0 m-0">
            {/* <img className='mx-auto' src={require('../images/bottomright.jpg')} alt="Right Image" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }} /> */}

            {/* <div className='position-relative rounded-4' style={{position: 'relative', maxWidth: '100%'}}>
            {showPlayButton && (
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <img src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/play-button.png" alt="Play" style={{width: '90px', height: '90px', cursor: 'pointer'}} onClick={handlePlay} />
                </div>
            )}
            <video controls width="100%" poster="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/video-poster.png" onPlay={handlePlay}>
                <source src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/video/Final+-+Clean+with+New+Logo!(1).mp4" type="video/mp4"/>
                Sorry, your browser doesn't support embedded videos.
            </video>
        </div> */}

            <div className="position-relative rounded-4" style={{ position: "relative", maxWidth: "100%" }}>
              {/* {showPlayButton && (
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <img src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/play-button.png" className="video-play" alt="Play" style={{width: '90px', height: '90px', cursor: 'pointer'}} onClick={handlePlay} />
                </div>
            )} */}
              <video id="video" controls width="100%" poster="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/images/video-poster.png" onPause={handlePause}>
                <source src="https://s3.eu-west-2.amazonaws.com/pfw.storage.bucket/video/Final+-+Clean+with+New+Logo!(1).mp4" type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className="social-share" style={{ height: 500 }}>
        <div className="background-image p-5">
          <div className="align-items-center row" style={{ paddingTop: 150 }}>
            <h2 className="l-title text-center text-white">Want to know more about CITC?</h2>
            <div className="social-links mt-5" style={{ maxWidth: "500px" }}>
              <a href="https://www.facebook.com/citcmancity/" className="text-center" target="_blank" rel="noopener noreferrer">
                <img className="mx-auto fb" src={require("../images/fb.png")} alt="Facebook" />

                {/* style={{ width: '100px', height: '100x', objectFit: 'cover', objectPosition: 'center' }} */}
              </a>
              <a href="https://twitter.com/citcmancity" className="text-center" target="_blank" rel="noopener noreferrer">
                <img className="mx-auto twitter" src={require("../images/twitter.png")} alt="Twitter" />
              </a>
              <a href="https://www.instagram.com/citcmancity/?hl=en" className="text-center" target="_blank" rel="noopener noreferrer">
                <img className="mx-auto instagram" src={require("../images/instagram.png")} alt="Instagram" />
              </a>
              <a href="https://uk.linkedin.com/company/city-in-the-community" className="text-center" target="_blank" rel="noopener noreferrer">
                <img className="mx-auto linkedin" src={require("../images/linkedin.png")} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='charity_section font-face-sh mb-5 pb-5'>
        <div className='container-2'>
          <div className='charity_title text-center py-5 px-md-5'>
            <h2 className='black-clr display-4 text-uppercase text-center px-md-5 lh-1 font-face-sh-bold'>City in the Community</h2>
            <p className='branding-2 fs-5 lh-1 mt-4 w-50 m-auto'>Building better lives through sport</p>
            <p className='black-clr fs-6 lh-1 mt-5'>Read more here</p>
            <div style={{ marginTop: -35 }}>
              <img src={Arrow} alt="Arrow" />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-6 pe-5'>
              <p className='branding-3 fs-5 lh-2'>City in the Community is the Official Charity of City in the Community Football Club, committed to our vision for a healthy, inclusive and thriving community.</p>
              <p className='branding-3 fs-5 lh-2'>With the passionate pursuit of building better lives through sport, we connect City in the Community to young people and adults across South West London. We work with a broad range of the community, supporting disabled young people, refugees, socially isolated adults, people with mental health challenges and many others.</p>
              <p className='branding-3 fs-5 lh-2'>We change the lives of over 10,000 people every year. We inspire and engage through sport, improving the physical health and mental wellbeing of our participants, while supporting people across our community to feel socially connected and confident.</p>
              <p className='branding-3 fs-5 lh-2'>Help maintain these impactful projects and become a Foundation Supporter today, check out the links below to find out more.</p>
              <Link to={'/signup'} className="btn bg-branding-btn shadow-lg text-white border-0 w-75 mb-5 rounded-pill py-3 mt-5 lh-1">Find out more</Link>
              <br />
              <Link to={'/signup'} className="btn bg-branding-btn shadow-lg text-white border-0 w-75 mb-5 rounded-pill py-3 lh-1">Support us</Link>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-6'>
              <div className='row'>
                <div className='col-sm-6 col-md-12 col-lg-6 col-6 pe-4'>
                  <img className='w-100 rounded-3' src={require('../images/charity/image1.jpg')} alt="charity-image1" />
                </div>
                <div className='col-sm-6 col-md-12 col-lg-6 col-6 ps-4'>
                  <img className='w-100 rounded-3' src={require('../images/charity/image2.jpg')} alt="charity-image2" />
                </div>
              </div>
              <div className='row pt-4 mt-3'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <img className='w-100 rounded-3' src={require('../images/charity/image3.jpg')} alt="charity-image3" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section> */}

      <section>
        {/* <div className='draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100' style={{zIndex : 9999999 }}> */}

        {!paymentDetails && SubscriptionPlan ? (
          <section className="subscription-plan-home font-face-sh draw-entry position-fixed px-3 py-3 bottom-0 bg-branding-1 w-100" style={{ zIndex: 999 }}>
            <div className="row align-items-center">
              <div className="col-sm-9 col-md-9 col-lg-9 col-9">
                <h1 className="text-light s-title">Enter the draw</h1>
                <p className="text-light">How many tickets would you like to buy?</p>
              </div>
              <div
                className="col-sm-3 col-md-3 col-lg-3 col-3 text-center border-top bg-white"
                style={{ backgroundColor: "#000", borderBottomLeftRadius: 70, borderBottomRightRadius: 70, padding: "14px 0px", marginTop: "-23px" }}
              >
                <h4 className="text-dark">Total</h4>
                <div className="subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1">£</div>
                <div className="subscription-plan-price_amount branding-1 display-3 font-face-sh-bold">{amount}</div>
              </div>
            </div>
            <div className="subscription-plan-container mx-auto mt-2" style={{ maxWidth: 700 }}>
              <div
                className={Freemodal ? "steps-subscription-plan px-0 position-relative pt-5 d-none" : "steps-subscription-plan px-0 position-relative d-block"}
                style={{ zIndex: 999 }}
              >
                <div className="ticket-entry">
                  <div className="form-check form-switch">
                    <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">
                      <p>{!isoneoff ? "Tick to make a one-off entry into the next draw" : "Untick to make a Subscription entry into the next draw"}</p>
                    </label>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-8 col-md-8 col-lg-8 col-8">
                      <div className="step-subscription-plan-selector home-data">
                        <ul className="d-flex unstyled list-unstyled gap-3">
                          <li className={isActive1 ? "active" : ""} onClick={() => handleClick("first", 5, 1)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none bra">
                                <strong>£5</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>1
                            </strong>
                          </li>
                          <li className={isActive2 ? "position-relative active" : "position-relative"} onClick={() => handleClick("second", 10, 2)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£10</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>2
                            </strong>
                          </li>
                          <li className={isActive3 ? "active" : ""} onClick={() => handleClick("third", 25, 5)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£25</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>5
                            </strong>
                          </li>
                          <li className={isActive4 ? "active" : ""} onClick={() => handleClick("fourth", 50, 10)}>
                            <strong
                              className="ticket-price d-flex branding-1 justify-content-center align-items-center branding1 rounded-circle"
                              style={{ backgroundColor: "#fff", width: 42, height: 42, alignItems: "center", justifyContent: "center", display: "flex" }}
                            >
                              <div className="subscription-price fs-5 d-none">
                                <strong>£50</strong>
                              </div>
                              <div className="subscription-period fs-6 d-none">{!isoneoff ? "/month" : ""}</div>
                              10
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 col-4">
                      <div className="step-subscription-button text-center d-flex justify-content-center">
                        {Moment() < Moment(DrawData.drawStartDate) ? (
                          <>
                            <button
                              onClick={handleShow}
                              className="btn bg-branding-2 border-0 rounded-pill py-2 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center text-uppercase"
                              style={{ width: 400, height: 48 }}
                            >
                              <span>{isoneoff ? "Buy Now" : "Subscribe"}</span>
                            </button>
                            <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal-startShow">
                              <Modal.Header closeButton>
                                <Modal.Title>Draw has not started yet</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="text-center">
                                  <img className="mx-auto w-50 mb-3" src={require("../images/mancity-horizontal-dark.png")} style={{ width: 66 }} />
                                  <p className="fs-3">
                                    This draw will start at{" "}
                                    <span className="branding-2">
                                      {DrawData?.drawEndDate ? Moment(DrawData.drawStartDate).format("MMMM Do, [at] ha") : "-----, /--/--/-- 00:00PM"}
                                    </span>
                                  </p>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <span variant="secondary" onClick={handleClose}>
                                  Close
                                </span>
                              </Modal.Footer>
                            </Modal>
                          </>
                        ) : (
                          <button
                            onClick={() => toPaymentDetails(true)}
                            className="btn bg-branding-2 text-uppercase border-0 rounded-pill py-2 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center cta-large-button"
                            style={{ width: 400, height: 48 }}
                          >
                            <span>{!isoneoff ? "Subscribe" : "Buy Now"}</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Freemodal ? "free-entries-modal d-block position-relative" : "free-entries-modal d-none"}>
                <div className="free-entry-modal_title_block mb-5 pb-5">
                  <img
                    className="cross bg-white rounded-3 p-1 position-absolute end-0 top-0"
                    src={Cross}
                    style={{ width: 56, boxShadow: "0 4px 16px rgb(20 19 38 / 30%)", cursor: "pointer" }}
                    onClick={() => setFreemodal(!Freemodal)}
                  />
                  <h2 className="free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5">Free Prize Draw</h2>
                  <p className="free-entry-modal_text text-light text-center fs-5 mx-auto mt-5" style={{ maxWidth: 375 }}>
                    While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.
                  </p>
                </div>
                <div className="free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex" style={{ maxWidth: 375 }}>
                  <div className="free-entry-icon">
                    <img src={FreeEntryIcon} style={{ width: 24 }} />
                  </div>
                  <div className="free-entry-description ps-4 fs-14 lh-1">
                    <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                    <p>
                      * First Name
                      <br />
                      * Last Name
                      <br />* Email
                    </p>
                    <p>
                      to: PFW Holdings Ltd
                      <br />
                      Play Fund Win Office #300,
                      <br />4 Blenheim Court,
                      <br />
                      Peppercorn Close,
                      <br />
                      Peterborough,
                      <br />
                      PE1 2DU
                      <br />
                      <br />
                      You will be entered into the prize draws for the month in which we receive your post entries.
                      <br />
                      <br />
                      Note: This will not result in any contributions to the Foundation
                    </p>
                    <div className="emoji-icon text-center pe-5 mt-4 pt-2">
                      <img className="mx-auto" src={require("../images/emoji_icon.png")} style={{ width: 66 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : paymentDetails && !oneShow ? (
          <section
            className="personal-details font-face-sh py-5 position-fixed"
            style={{ backgroundColor: "#fff", left: 0, right: 0, top: 0, bottom: 0, zIndex: 999999, overflow: "scroll" }}
          >
            <a className="black-clr text-decoration-none" href="/">
              <img className="mx-3" src={require("../images/back.png")} style={{ width: 32 }} />
            </a>
            <div className="subscription-plan-container mx-auto" style={{ maxWidth: 780 }}>
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12 step-user-details pe-5">
                  <div className="step-plan_step branding-1 rounded-pill text-center mx-auto" style={{ backgroundColor: "#f7f7fd", width: 112, height: 45, lineHeight: "45px" }}>
                    <strong>Step 2 of 2</strong>
                  </div>
                  {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}
                  <p className="text-center" style={{ color: "#808080", fontSize: "18px" }}>
                    {isoneoff ? "" : "We’ll take your amount "}
                  </p>
                  <form method="post">
                    {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                    {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                    {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                    <div className="form-checkbox">
                      <ul className="checkbox-list list-unstyled">
                        <li className="checkbox-item mb-4">
                          <label className="input-checkbox d-flex">
                            <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, I’d like to receive updates about City in the Community via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label className="input-checkbox d-flex">
                            <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span className="input-checkbox-label">Yes, City in the Community can contact me about their work via email.</span>
                          </label>
                        </li>
                        <li className="checkbox-item">
                          <label
                            className="step-payment-details_consent_description mt-3 input-checkbox d-flex"
                            style={{ backgroundColor: "rgb(198,29,35,.1)", color: "#302d51", padding: "14px 18px", borderRadius: 20, fontSize: 13 }}
                          >
                            <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                            <span className="input-checkbox_check"></span>
                            <span>
                              By clicking on Confirm you agree to City in the Community{" "}
                              <Link to={"/terms-conditions"} onClick={() => window.scrollTo(0, 0)} style={{ color: "#302d51" }}>
                                Terms & Conditions of Use
                              </Link>
                              ,{" "}
                              <Link to={"/privacy-policy"} style={{ color: "#302d51" }}>
                                Privacy Policy
                              </Link>{" "}
                              and that you <strong>are at least 18 years old.</strong>
                            </span>
                          </label>
                        </li>
                        <li className="checkbox-item pt-4 pb-0">
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={event => setFullName(event.target.value)}
                            className="input mb-3"
                          />
                          {/* <input type="text" id="firstname" value={userFirstName} onChange={(event) => setFirstName(event.target.value)} placeholder="Enter first name" className="input" style={{ display: 'block' }} />
                            <input type="text" id="lastname" value={userLastName} onChange={(event) => setLastName(event.target.value)} placeholder="Enter last name" className="input" style={{ display: 'block' }} /> */}
                          <input
                            type="email"
                            id="email"
                            value={userEmail}
                            onChange={event => setEmail(event.target.value)}
                            placeholder="Email address"
                            className="input"
                            style={{ display: "block" }}
                          />
                        </li>
                      </ul>
                    </div>
                    {/* <p className='step-payment-details_consent_description mt-5' style={{ backgroundColor: 'rgb(198,29,35,.1)', color: '#302d51', padding: '14px 18px', borderRadius: 20, fontSize: 13 }}>By clicking on Confirm you agree to City in the Community <Link to={"/terms-conditions"} style={{ color: '#302d51' }}>Terms & Conditions of Use</Link>, <Link to={"/privacy-policy"} style={{ color: '#302d51' }}>Privacy Policy</Link> and that you <strong>are at least 18 years old.</strong></p> */}
                    <div className="step-subscription-button text-center mb-3 d-flex justify-content-center"></div>
                  </form>
                  <button
                    onClick={checkConsents}
                    className="btn bg-branding-green border-0 rounded-pill py-2 mt-3 lh-2 desktop-btn text-light position-relative d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: 48 }}
                  >
                    Pay now
                  </button>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0">
                  <div className="user-details-info bg-branding-1 rounded-4" style={{ padding: "35px 35px 19px", boxShadow: "0 15px 50px -30px #000000" }}>
                    <h3 className="text-light fs-5 mb-4">Summary</h3>
                    <div className="summary-info-impact pb-3">
                      <div className="summary-info-impact_title text-light d-flex align-items-center" style={{ fontSize: 18 }}>
                        {entries} chances to win
                      </div>
                      <div className="summary-info-impact_period lh-1" style={{ color: "rgb(255,255,255,0.7)", paddingLeft: 35 }}>
                        every draw
                      </div>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <div className="summary-info-price d-flex align-items-center pb-2">
                      <span className="summary-info-price_amount text-light display-4">£ {amount}</span>
                      <span className="summary-info-price_period ps-3" style={{ color: "rgb(255,255,255,0.7)" }}>
                        {isoneoff ? "" : "per month"}
                      </span>
                    </div>
                    <hr className="bg-light w-100 border-0" style={{ height: 1, opacity: 0.1 }} />
                    <p className="summary-info-upgrade-text m-0" style={{ color: "rgb(255,255,255,0.7)" }}>
                      Upgrade or cancel anytime
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {isStripe ? (
          <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Elements stripe={stripePromise}>
                <StripeForm
                  UserID={UserID}
                  oneoff={isoneoff}
                  show={true}
                  email={userEmail}
                  name={fullName}
                  address1="-"
                  address2="-"
                  postcode="-"
                  amount={amount}
                  entries={entries}
                  hideModal={hideModal}
                  navigate={navigate}
                  timeout={timeout}
                />
              </Elements>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        <ToastContainer />

        {/* </div> */}
      </section>

      <footer className="font-face-sh mt-5 position-relative home-footer">
        <div className="container">
          <div className="footer-logo">
            <img className="" src={LogoFoundationGray} alt="logo-foundation-gray" style={{ width: 270 }} />
          </div>
          <div className="row pt-4">
            <div className="col-sm-12 col-md-12 col-lg-5 black-clr">
              <h4 className="mb-4 pb-2 pt-2 s-body">Contact us</h4>
              <div className="footer-address d-flex align-items-start">
                <img className="me-3" src={FooterIconAddress} alt="FooterIconAddress" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>Post: PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU</p>
              </div>
              <div className="footer-email d-flex align-items-start">
                <img className="me-3" src={FooterIconEmail} alt="FooterIconEmail" style={{ width: 24 }} />
                <p style={{ fontSize: 14 }}>
                  Support:{" "}
                  <a href="mailto:hello@playfundwin.com" className="text-decoration-none black-clr branding-1-hover">
                    hello@playfundwin.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 mt-md-5">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 black-clr mb-3">
                  <p className="mt-4 pt-2 xs-body-font-bold">Follow us</p>
                  <div className="d-flex">
                    <a className="branding-2 text-decoration-none" href="https://facebook.com/PlayFundWin/" target="_blank">
                      <img className="me-3" src={Facebook} alt="Facebook" style={{ width: 24 }} />
                    </a>
                    <a className="branding-2 text-decoration-none" href="https://twitter.com/PlayFundWin" target="_blank">
                      <img className="me-3" src={Twitter} alt="Twitter" style={{ width: 24 }} />
                    </a>
                    <a className="branding-2 text-decoration-none" href="https://www.instagram.com/playfundwin" target="_blank">
                      <img className="me-3" src={Instagram} alt="Instagram" style={{ width: 24 }} />
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex mt-md-5 pt-2">
                    <img className="me-3" src={Visa} alt="Visa" style={{ width: 54 }} />
                    <img className="me-3" src={Mastercard} alt="Mastercard" style={{ width: 54 }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-4  mt-md-5 black-clr ps-md-6 pb-2">
              <p className="mt-4 pt-3" style={{ fontSize: 14 }}>
                City in the Community Prize Draw is operated by{" "}
                <a className="text-decoration-none black-clr branding-1-hover" href="https://playfundwin.com/" target="_blank">
                  Play Fund Win
                </a>
              </p>
              <div className="d-flex" style={{ fontSize: 14 }}>
                <Link className="black-clr text-decoration-none pe-5 branding-1-hover xs-body-font-bold" onClick={() => window.scrollTo(0, 0)} to={"/terms-conditions"}>
                  Terms & Conditions
                </Link>
                <Link className="black-clr text-decoration-none branding-1-hover xs-body-font-bold" onClick={() => window.scrollTo(0, 0)} to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
